import React from "react";

const card = ({ title, descripition, img }) => {
  return (
    <>
      <img src={img} alt="icon" />{" "}
      <h1 className="text-[#000] font-primary lg:text-[24px] md:text-[16px] text-[13px] font-[700] leading-[34px] ">
        {title}
      </h1>
      <p className="text-[#000] md:mt-[25px] text-center font-opensans  lg:text-[16px] md:text-[10px] text-[9px] lg:leading-[34px] ">
        {descripition}
      </p>
    </>
  );
};

export default card;

import React from "react";
import Card from "./About-us-Card";
import ServiceIcon from "../../assets/about-us-images/inService.svg";
import globalIcon from "../../assets/about-us-images/global.svg";
import knowledgeicon from "../../assets/about-us-images/knowledge.svg";

const Guarantee = () => {
  return (
    <div className="guaranteeSection lg:mt-0 mt-[-100px] md:h-[900px] lg:h-[1100px] h-[1065px] lg:max-w-[1440px] mx-auto ">
      <div
        className=" flex  xl:flex-row  lg:flex-col md:flex-col min-[150px]:flex-col  xl:gap-0 gap-[50px] pt-[150px] xl:pl-[6.5rem] lg:px-6 md:pl-14 min-[150px]:pl-4  min-[150px]:pr-4 
      md:px-[60px]  h-[700px] "
      >
        <div className="flex-[0.8] flex justify-center flex-col">
          <h3 className="text-[#006BFF] text-[18px] font-primary leading-[55px] font-[800]">
            HOW WE DO IT
          </h3>
          <h1 className="text-[#000] text-[35px] font-primary leading-[48px] font-[700]">
            The Tomedes Guarantee
          </h1>
          <p className="text-[#000] mt-[43px] lg:text-2xl text-[16px] font-opensans  leading-[32px] ">
            Formal Translations is a subsidiary of the Tomedes group of
            companies, which brings language expertise and top-notch service
            that has been honed over a decade of experience.
          </p>
        </div>

        <div className="flex-1 flex md:flex-row flex-col justify-between md:gap-[20px] gap-[200px] relative">
          <div
            className=" upperCard lg:h-[280px] md:h-[180px] min-[150px]:h-[150px] absolute flex flex-col md:gap-0 gap-1 items-center justify-center bg-[#F9FAFB] 
          lg:w-[320px]  md:w-[240px]  min-[150px]:w-[200px] rounded-[8px]"
          >
            <Card
              title="All-in service"
              descripition="Full-suite translation services and consultancy under one roof."
              img={ServiceIcon}
            />
          </div>
          <div className="centeredCard absolute md:right-[35%] right-0  top-[175px] md:top-[100px]  xl:right-[-15px] xl:top-[180px] lg:h-[280px]  md:h-[180px] h-[150px] flex flex-col items-center justify-center bg-[#F9FAFB] lg:w-[320px] md:w-[240px] w-[200px] rounded-[8px]">
            <Card
              title="Global & local expertise"
              descripition="Wide coverage and attention to details."
              img={globalIcon}
            />
          </div>
          <div
            className=" lowerCard absolute xl:top-[490px] top-[350px]  md:top-0 xl:left-[135px]  md:right-0 lg:h-[280px] md:h-[180px] h-[150px] flex flex-col items-center justify-center bg-[#F9FAFB] lg:w-[320px] 
             md:w-[240px] w-[200px] 
          rounded-[8px]"
          >
            <Card
              title="Quick delivery"
              descripition="Round-the-clock service, so you get your 
documents back within hours."
              img={knowledgeicon}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guarantee;

import React from "react";

const TeamSection = () => {
  return (
    <div className="max-w-[1240px] md:px-0 px-4 lg:mt-[-100px] mx-auto flex flex-col items-center">
      <h1 className="text-[#000] lg:text-[65px] text-[40px] font-[700] font-primary">
        Meet Our Team
      </h1>
      <p className="text-[18px] text-black text-center font-primary font-[300]  md:mt-0 mt-5">
        Meet the Formal Team. Best of the best you could introduce your family
      </p>
    </div>
  );
};

export default TeamSection;

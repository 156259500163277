import Banner from "../LegalTranscription/About-us/Banner";
import Guarantee from "../LegalTranscription/About-us/Guarantee";
import OurMission from "../LegalTranscription/About-us/OurMission";
import OurServices from "../LegalTranscription/About-us/OurServices";
import Process from "../LegalTranscription/About-us/Process";
import TeamInfo from "../LegalTranscription/About-us/TeamInfo";
import TeamSection from "../LegalTranscription/About-us/TeamSection";
import HelpSection from "../LegalTranscription/Home-v2/notSure";
import Layout from "components/Layout";
import React from "react";

const About = () => {
  return (
    <Layout>
      <section className="bg-[#006BFF]">
        <div
          className="mt-[5rem] lg:px-6 xl:px-4 lg:max-w-[1272px]  
        md:px-[4rem] min-[150px]:px-4 mx-auto"
        >
          <Banner />
        </div>
      </section>

      <section className="my-[50px] lg:px-6 xl:px-4  lg:max-w-[1272px]  md:px-[4rem] px-0 mx-auto min-[150px]:px-4">
        <OurMission />

        <div className="mt-[50px] lg:max-w-[870px] lg:px-6 xl:px-0 md:px-0 mx-auto flex flex-col lg:items-center gap-[25px]">
          <h3 className="text-primary text-[18px] font-primary leading-[55px] font-[800]">
            HOW WE DO IT
          </h3>
          <h1 className="text-[#000] lg:text-[40px] text-[35px] font-primary leading-[48px] font-[700]">
            We believe in a philosophy of service.
          </h1>
          <p className="text-[#000] text-[16px] font-opensans lg:text-center leading-[32px] ">
            The client is at the heart of everything we do. Our aim is to
            provide you with the best experience possible, from the moment you
            contact us to well after delivery.
          </p>
        </div>
        <OurServices />
      </section>
      <Process />
      <Guarantee />
      <TeamSection />
      <TeamInfo />
      <section className="mb-[50px] lg:mt-0 md:mt-[-50px] lg:px-2 xl:px-0 lg:max-w-[1240px] md:px-[3.8rem]  mx-auto">
        <HelpSection />
      </section>
    </Layout>
  );
};

export default About;

import React from "react";

const OurMission = () => {
  const data = [
    {
      section: "OUR MISSION",
      id: 1,
      title:
        "Our mission is to provide the best service built on complete consideration of your needs and maximum efficiency.",
      descripition:
        "To this end, we maintain a network of highly-qualified certified translators all over the world who can carry you through the complexities and regulations surrounding certified translation in different countries.",
      style: "lg:bg-[#FFF4CC]",
    },

    {
      section: "OUR VISION",
      id: 2,
      title:
        "We aim to be the standard when it comes to certified translation.",
      descripition:
        "Any translation company can offer certified translation services. But not everyone can make it a perfect experience that leaves the client completely satisfied with what they get.",
      style: "lg:bg-[#F0F8FF]",
    },
  ];

  return (
    <div className="flex flex-col gap-[50px]">
      {data.map((item) => (
        <div
          key={item.id}
          className={`${item.style} bg-[#F0F8FF] rounded-[16px] flex flex-col gap-[25px] lg:items-center justify-center lg:px-[180px] md:px-8 px-4 md:[32px] py-[50px]`}
        >
          <h3 className="text-[#006BFF] text-[18px] font-primary leading-[55px] font-[800]">
            {item.section}
          </h3>
          <h1 className="text-[#000] lg:text-center lg:text-[40px] text-[35px] font-primary leading-[48px] font-[700]">
            {item.title}
          </h1>
          <p className="text-[#000] text-[16px] lg:text-center font-opensans leading-[32px] ">
            {item.descripition}
          </p>
        </div>
      ))}
    </div>
  );
};

export default OurMission;

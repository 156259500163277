import img from "../../assets/about-us-images/img.svg";
import img2 from "../../assets/about-us-images/img2.svg";
import React from "react";

const Process = () => {
  return (
    <div className="lg:bg-[#001323] bg-[#f0f8ff]   py-[50px] rounded-[16px] mt-[50px] lg:text-[#FFF] text-primary">
      <div className=" lg:px-6 xl:px-0  flex flex-col gap-[50px] items-center lg:max-w-[1240px]  md:px-[4rem] min-[150px]:px-4  mx-auto">
        <img src={img} className="lg:block hidden" />
        <img src={img2} className="lg:hidden block" />
        <p className="text-[35px] lg:text-white text-[#006BFF] font-primary text-center font-[500] pb-[50px] leading-[50px]">
          We believe that improvement is a constant process, one that is
          necessary to foster trustworthiness and credibility.
        </p>
      </div>
    </div>
  );
};

export default Process;

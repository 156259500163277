import Flesh from "../../assets/about-us-images/Flesh.svg";
import frame1 from "../../assets/about-us-images/frame1.svg";
import frame2 from "../../assets/about-us-images/frame2.svg";
import frame3 from "../../assets/about-us-images/frame3.svg";
import frame4 from "../../assets/about-us-images/frame4.png";
import frame5 from "../../assets/about-us-images/frame5.png";
import React from "react";

const TeamInfo = () => {
  return (
    <div className=" flex flex-col lg:gap-[125px] md:gap-[100px] gap-[75px] lg:pb-[110px]  max-w-[1440px] mx-auto lg:px-0 md:px-[60px] lg:mt-[108px] mt-[60px]">
      <div className="flex gap-10 relative">
        <div className="flex-1 bg-[#E2F2FF]  lg:py-[134px] py-[50px] lg:rounded-r-[100px] rounded-br-[40px]  md:px-[100px] min-[150px]:px-[15px]">
          <h3 className="text-[#006BFF] text-[18px] font-primary leading-[55px] font-[800]">
            MEET OUR TEAM
          </h3>
          <h1 className="text-[#1F53FF] text-[35px] font-primary leading-[48px] font-[700]">
            Our translators
          </h1>
          <p className="text-[#000] lg:text-[20px] text-[16px] font-opensans mt-[20px] leading-[32px] ">
            Everything is made possible by our network of certified translators
            who are not only language experts but masters in navigating the
            complex legal or official challenges that come with translating and
            certifying official documents in their home country.
          </p>
        </div>
        <div className="flex-[0.5] lg:block hidden mt-[110px] xl:pr-[100px]  lg:pr-8">
          <img src={Flesh} alt="flesh" />
        </div>

        <img
          src={frame1}
          alt="frame1"
          className="absolute  md:block hidden lg:h-[313px] lg:w-[313px]  h-[150px] w-[150px] md:bottom-[-115px] z-10  md:right-[-65px]  xl:top-[-108px] xl:right-[354px] lg:top-[-108px] lg:right-[210px]"
        />
        <img
          src={frame2}
          alt="frame1"
          className="lg:h-[313px] lg:w-[313px] h-[104px] w-[104px] absolute right-[15px] bottom-[-90px] md:right-[140px] xl:right-[110px] lg:top-[301px]  lg:right-[15px] z-10  "
        />
      </div>

      <div className="flex relative gap-10 ">
        <div className="flex-[0.5] mt-[110px] lg:block hidden  xl:pl-[100px]  lg:pl-6">
          <img src={Flesh} alt="flesh" />
        </div>
        <div className="flex-1 bg-[#F0F8FF] lg:py-[134px] py-[50px] rounded-tl-[40px]  lg:rounded-l-[100px] md:px-[100px] min-[150px]:px-[15px]">
          <h3 className="text-[#006BFF] text-[18px] font-primary leading-[55px] font-[800]">
            MEET OUR TEAM
          </h3>
          <h1 className="text-[#1F53FF] lg:text-[48px] text-[32px] font-primary leading-[48px] font-[700]">
            Our project managers
          </h1>
          <p className="text-[#000] lg:text-[20px] text-[16px] font-opensans mt-[20px] leading-[32px] ">
            Your one point of contact throughout your translation journey. They
            are there to ensure that all your needs are met and all your
            documents are handled with utmost professionalism for high quality
            and clockwork efficiency.
          </p>
        </div>

        <img
          src={frame3}
          alt="frame1"
          className="absolute  lg:h-[313px] lg:w-[313px]  h-[104px] w-[104px]  xl:left-[585px] lg:top-[-225px] lg:left-[400px] md:right-[298px] top-[-125px] right-[180px]  frame3  "
        />
        <img
          src={frame4}
          alt="frame1"
          className="absolute  lg:h-[313px] lg:w-[313px]  h-[104px] w-[104px] xl:left-[108px] lg:left-[35px]  xl:top-[-50px]   md:right-[430px]  top-[-70px]  right-[70%]"
        />
        <img
          src={frame5}
          alt="frame1"
          className="absolute md:block hidden lg:h-[313px] lg:w-[313px]  h-[172px] w-[172px]  xl:left-[298px] z-10  lg:left-[280px] lg:top-[313px] md:left-[-104px] md:top-[-130px]"
        />
      </div>
    </div>
  );
};

export default TeamInfo;

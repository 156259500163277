import React from "react";

const Banner = () => {
  return (
    <div className="flex lg:flex-row flex-col  lg:gap-0 gap-[25px] md:py-[110px]  py-[60px] text-[#F5F5F5] text-[16px] font-opensans ">
      <div className="flex-1">
        <h1 className=" lg:text-[45px] text-[40px] font-primary font-[800]">
          About Us
        </h1>
        <p className="lg:mt-0 mt-[25px]">
          What makes Formal Translations different? We care.
        </p>
      </div>
      <div className="flex-1 leading-[30px]">
        Formal Translations offers top-notch translation services for
        certificates and other official documents in over 120 languages around
        the world. Established in 2022 under the Tomedes group of companies,
        Formal Translations blends certified translation expertise with a
        client-centered philosophy to elevate your translation journey into a
        seamless, meaningful, and 100% worry-free experience.
      </div>
    </div>
  );
};

export default Banner;

import React from "react";
import Image1 from "../../assets/about-us-images/transparency.svg";
import Image2 from "../../assets/about-us-images/simplicity.svg";
import Image3 from "../../assets/about-us-images/knowledge.svg";

const OurServices = () => {
  const data = [
    {
      id: 1,
      img: Image1,
      title: "We believe in transparency.",
      descripition:
        "The client is at the heart of everything we do. Our aim is to provide you with the best experience possible, from the moment you contact us to well after delivery.",
    },
    {
      id: 2,
      img: Image2,
      title: " We believe in simplicity.",
      descripition:
        "Certified translation has its challenges. We handle all the tricky bits so you don’t have to.",
    },
    {
      id: 3,
      img: Image3,
      title: "We believe in knowledge.",
      descripition:
        "We are committed to continuous learning and stay on top of every piece of information needed to provide you with comprehensive service that is always accurate and reliable.",
    },
  ];
  return (
    <div
      className="grid lg:grid-cols-3 gap-[55px] grid-cols-1 mt-[30px] lg:px-0  
     lg:max-w-[1240px]  md:px-0   mx-auto"
    >
      {data.map((item) => (
        <div key={item.id} className="flex flex-col gap-[18px] ">
          <img src={item.img} alt="symbol" className="w-[60px] h-[60px] " />
          <h1 className="text-2xl font-primary font-[700] text-[#000]">
            {item?.title}
          </h1>
          <p className="text-black">{item?.descripition}</p>
        </div>
      ))}
    </div>
  );
};

export default OurServices;
